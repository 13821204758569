<template>
  <div class="wish-detail">
    <!-- <van-nav-bar title="心愿详情" left-arrow @click-left="onClickLeft" /> -->
    <div class="wish-detail-body">
      <div class="img">
        <img :src="detailData.materialId" alt />
      </div>
      <h3>{{detailData.wishTitle}}</h3>
      <div class="post-info">
        <p>许愿人：{{detailData.wishPersonName}}</p>
        <p>发布时间：{{detailData.startTime}}</p>
        <p>截至时间：{{detailData.endTime}}</p>
      </div>
      <div class="wish-content">
        <h5>心愿内容</h5>
        <div class="content">{{detailData.description}}</div>
      </div>
      <div class="checked-info">
        <h5>审核信息</h5>
        <p>审核状态：{{detailData.auditStatus==1?'通过':'驳回'}}</p>
        <p>审核人：{{detailData.auditPersonnelName}}</p>
        <p>审核时间：{{detailData.auditAt}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { detail } from '@/api/tinyWish';
export default {
  data() {
    return {
      detailData: {}
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    }
  },
  mounted() {
    detail({
      id: this.$route.params.id
    })
      .then(res => {
        console.log(res);
        this.detailData = res.data;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.wish-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .wish-detail-body {
    background: #f3f3f3;
    flex: 1;
    .img {
      width: 750 * $px;
      height: 320 * $px;
      background: url('./../../assets/img/list.png') center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      background: #fff;
      font-size: 32 * $px;
      font-family: PingFang SC;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      height: 48 * $px;
      line-height: 48 * $px;
      padding: 24 * $px 0 24 * $px 24 * $px;
      border-bottom: 2 * $px solid #f3f3f3;
    }
    .post-info {
      background: #fff;
      padding: 24 * $px 0 24 * $px 24 * $px;
      font-size: 24 * $px;
      color: rgba(85, 85, 85, 1);
      p:nth-of-type(2) {
        margin: 14 * $px 0;
      }
    }
    .wish-content {
      margin-top: 24 * $px;
      background: #fff;
      padding: 27 * $px 24 * $px;
      h5 {
        font-size: 32 * $px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 24 * $px;
      }
      .content {
        font-size: 28 * $px;
        color: rgba(34, 34, 34, 1);
      }
    }
    .checked-info {
      margin-top: 24 * $px;
      background: #fff;
      padding: 27 * $px 24 * $px;
      h5 {
        font-size: 32 * $px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 24 * $px;
      }
      p {
        font-size: 28 * $px;
        color: rgba(34, 34, 34, 1);
        &:nth-of-type(2) {
          margin: 10 * $px 0;
        }
      }
    }
  }
}
</style>